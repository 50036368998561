import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration, HttpClient as HttpClientIntegration } from '@sentry/integrations';
import { getChannel } from './lib/campaignTracking.js';

const PHONE_ELEMENT_SELECTOR = '.phone-swap';

const replacePhoneNumbers = (channel) => {
  const phoneNumbers = document.querySelectorAll(PHONE_ELEMENT_SELECTOR);
  phoneNumbers.forEach((phoneNumber) => {
    phoneNumber.innerText = channel.phone;
    if (phoneNumber.href) {
      // If the phone number is a link, we need to update the href as well
      phoneNumber.setAttribute('href', channel.hrefPhone);
    }
  });
};

const init = async () => {
  const channel = await getChannel();
  console.log(channel);
  if (channel && channel.phone) {
    replacePhoneNumbers(channel);
    // When the document is ready, we can replace the phone numbers
    document.addEventListener('DOMContentLoaded', () => {
      replacePhoneNumbers(channel);
    });
  }
};

Sentry.init({
  dsn: 'https://eb281717eee3c4830c516a600496edfe@o382513.ingest.sentry.io/4505723933491200',
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error'],
    }),
    new HttpClientIntegration({ failedRequestStatusCodes: [[500, 505], 507, [400, 404]] }),
  ],
});

init();
